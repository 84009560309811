<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <h5 class="mt-5 mb-2">Filtrar por fecha</h5>
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                      v-validate="'required'" name="startDate"></datepicker>
        </div>
        <div class="vx-col md:w-1/2">
          <datepicker :language="es" label="Hasta" v-model="endDate" id="endDate"
                      v-validate="'required'" name="startDate"></datepicker>
        </div>
        <div class="vx-col mt-2">
          <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
        </div>
      </div>
    </vx-card>

    <new :requests="requests" :warehouses="warehouses" :merchants="merchants" :products="products" @add="addDataSideBar"
         :isSidebarActive="newSidebar"
         @closeSidebar="newSidebar= false"/>

    <detail :data="sidebarData" :isSidebarActive="detailSidebar" @closeSidebar="detailSidebar= false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="sidebarData= selected, detailSidebar= true">
                            <span class="flex items-center">
                              <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver detalle</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="openUrl(selected.nubefact.enlace_del_pdf)">
                            <span class="flex items-center">
                              <feather-icon icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver PDF</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- Add new -->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center font-medium text-base text-primary border border-solid border-primary"
              @click="newSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>
        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Nro. Doc</vs-th>
        <vs-th>Fecha de traslado</vs-th>
        <vs-th sort-key="motive">Motivo</vs-th>
        <vs-th sort-key="user">Usuario</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.serial + '-' + tr.sequence }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.createdAt }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.motive }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.user.displayName }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon v-if="!tr.accepted" @click="verifyGuide(tr)" title="Verificar"
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 mr-5 hover:text-primary stroke-current"/>
            <feather-icon class="mr-2" title="Detalle" icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData= tr, detailSidebar= true"/>
            <feather-icon class="mr-2" title="Ver PDF" icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="openUrl(tr.nubefact.enlace_del_pdf)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import Detail from './Detail'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'

import { db, Timestamp } from '@/firebase/firebaseConfig'

import moment from "moment"

export default {
  components: {
    New,
    Detail,
    Datepicker
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      merchants: [],
      warehouses: [],
      products: [],
      requests: [],
      initProgress: false,

      // Data Sidebar
      newSidebar: false,
      detailSidebar: false,
      sidebarData: {},

      //Filters
      startDate: null,
      endDate: null,
      es: es,

      // Date
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
    }
  },
  async created () {
    try {
      //Get transfers
      this.initProgress = true
      await this.loadTransfers()
      await this.loadMerchants()
      await this.loadWarehouses()
      await this.loadProducts()
      await this.loadRequests()
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  methods: {
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDataSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Load transfers
     * @returns {Promise<void>}
     */
    async loadTransfers () {
      const querySnapshot = await db.collection('transfers').orderBy('createdAt', 'desc').limit(100).get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          sequence: doc.data().sequence || '',
          serial: doc.data().serial || '',
          accepted: doc.data().accepted || false
        }
        obj.createdAt = moment(doc.data().createdAt.toDate()).format('YYYY-MM-DD HH:mm')
        this.list.push(obj)
      })
    },
    /**
     * Get Merchants
     */
    async loadMerchants () {
      this.merchants = []
      const querySnapshot = await db.collection('merchants').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let merchant = {
          id: doc.id,
          name: doc.data().name,
          lastName: doc.data().lastName,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          license: doc.data().license
        }
        this.merchants.push(merchant)
      })
    },
    /**
     * Get warehouses
     * @returns {Promise<void>}
     */
    async loadWarehouses () {
      this.warehouses = []
      const querySnapshot = await db.collection('warehouses').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let warehouse = {
          id: doc.id,
          name: doc.data().name,
          address: doc.data().address,
          ruc: doc.data().ruc,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo,
          warehouseManager: doc.data().warehouseManager
        }
        this.warehouses.push(warehouse)
      })
    },
    /**
     * Get products
     * @returns {Promise<void>}
     */
    async loadProducts () {
      this.products = []
      const querySnapshot = await db.collection('products').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let product = {
          id: doc.id,
          brand: doc.data().brand,
          category: doc.data().category,
          name: doc.data().name,
          sku: doc.data().sku,
          label: doc.data().name + ' - ' + doc.data().sku,
          stock: doc.data().stock,
          cost: doc.data().price
        }
        this.products.push(product)
      })
    },
    /**
     * Load request of firestore
     * */
    async loadRequests () {
      const querySnapshot = await db.collection('requests').orderBy('createdAt', 'desc').limit(20).get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          numberOfDocument: doc.data().numberOfDocument,
          state: doc.data().state
        }
        obj.createdAt = doc.data().createdAt.toDate()
        this.requests.push(obj)
      })
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      await this.loadTransfers()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        this.initProgress = true
        this.list = []
        // Set start date
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        // Set end date
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)

        this.startDate.set
        const starTimes = Timestamp.fromDate(this.startDate)
        const endTimes = Timestamp.fromDate(this.endDate)
        const querySnapshot = await db.collection('transfers')
            .where('createdAt', '>=', starTimes)
            .where('createdAt', '<=', endTimes).get()
        querySnapshot.forEach((doc) => {
          this.list.push({
            id: doc.id,
            ...doc.data(),
            createdAt: moment(doc.data().createdAt.toDate()).format('YYYY-MM-DD HH:mm')
          })
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    // Open URL
    openUrl (url) {
      window.open(url, '_blank')
    },
    /**
     * Verify guide
     * @param tr
     */
    async verifyGuide (tr) {
      try {
        if (!tr.accepted) {
          const response = await this.$http.post('https://southamerica-east1-imvialex.cloudfunctions.net/nubefact-verifyGuide', {
            document: {
              'operacion': 'consultar_guia',
              'tipo_de_comprobante': 7,
              'serie': tr.serial,
              'numero': tr.sequence
            }
          }, {
            headers: {
              Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
            }
          })

          /// Update in firestore
          await db.collection('transfers').doc(tr.id).update({
            nubefact: {
              ...response.data.nubefact
            },
            accepted: response.data.nubefact.aceptada_por_sunat
          })
          // End

          if (response.data.nubefact.aceptada_por_sunat) {
            // Open PDF in browser
            window.open(response.data.nubefact.enlace_del_pdf, 'blank')
            // End
            // Update row
            tr.nubefact = {
              ...response.data.nubefact
            }
            tr.accepted = true
            // End

            this.$vs.notify({
              color: 'success',
              title: 'Guía de remisión',
              text: 'Guía aceptada por Sunat',
              time: 5000
            })
          }
          else {
            this.$vs.notify({
              color: 'warning',
              title: 'Guía de remisión',
              text: 'Guía no aceptada por Sunat',
              time: 5000
            })
          }
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
        })
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
