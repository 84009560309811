<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registro de traslado</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Motive -->
        <vs-textarea @blur="document.motive= trimString(document.motive)" label="Motivo*"
                     v-model="document.motive"
                     class="w-full" name="motive"
                     v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('motive')">{{ errors.first('motive') }}</span>

        <!--Warehouses-->
        <div class="vx-row">
          <!--Warehouse in-->
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Almacén de salida*</label>
            <v-select :disabled="addedProducts.length!==0" label="name" :options="warehouses"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.warehouseOut" class="mb-4 md:mb-0" name="warehouseOut"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('warehouseOut')">{{ errors.first('warehouseOut') }}</span>
          </div>

          <!--Warehouse out-->
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Almacén de llegada*</label>
            <v-select label="name" :options="warehouses" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.warehouseIn" class="mb-4 md:mb-0" name="warehouseIn"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('warehouseIn')">{{ errors.first('warehouseIn') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Merchant-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Mercaderista*</label>
          <v-select label="name" :options="merchants" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.merchant" class="mb-4 md:mb-0" name="merchant" v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('merchant')">{{ errors.first('merchant') }}</span>
        </div>
        <!--End-->

        <!--Requests-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Selecciona solicitud</label>
          <v-select label="numberOfDocument" :options="requests" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.request" class="mb-4 md:mb-0" name="request"/>
        </div>
        <!--End-->

        <hr class="mt-5">

        <!--Products-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Listado de productos</label>
          <v-select :options="allProducts" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="selectedProduct" class="mb-4 md:mb-0" name="product" v-validate="'required'"/>
        </div>
        <!--End-->

        <vs-button class="mt-5" @click="addProduct">Agregar</vs-button>

        <!--Added products-->
        <vs-table class="mt-5" style="padding-bottom: 14rem" :data="addedProducts">

          <template slot="thead">
            <vs-th>Nombre</vs-th>
            <vs-th>SKU</vs-th>
            <vs-th>Categoría</vs-th>
            <vs-th>Marca</vs-th>
            <vs-th>Stock</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Eliminar</vs-th>
            <vs-th>Series</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="tr.name">
                <p>{{ tr.name }}</p>
              </vs-td>

              <vs-td :data="tr.sku">
                <p> {{ tr.sku }}</p>
              </vs-td>

              <vs-td :data="tr.category">
                <p>{{ tr.category.name }}</p>
              </vs-td>

              <vs-td :data="tr.brand">
                <p>{{ tr.brand.name }}</p>
              </vs-td>

              <vs-td :data="tr.stock">
                <p>{{ tr.stock }}</p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input min="1" :max="tr.stock" style="width: 80px" type="number"
                            v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="ml-2" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="ml-2" icon="PlusIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="openDialog(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!---->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>

    <vs-prompt
        @cancel="clearDialog"
        @accept="acceptAlert"
        @close="closeDialog"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series"
        :active.sync="openSerialDialog">
      <div class="con-exemple-prompt">
        Ingresa los números de serie
        <vs-textarea rows="5" label="Series" v-model="serials" class="mt-4 mb-2 w-full"/>
      </div>
    </vs-prompt>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

const _ = require('lodash')
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    merchants: {
      type: Array,
      required: true
    },
    requests: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      openSerialDialog: false,
      selectedProduct: {},
      addedProducts: [],
      allProducts: [],
      serials: ''
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {}
        this.selectedProduct = {}
        this.addedProducts = []
        this.allProducts = _.cloneDeep(this.products)
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     */
    async submitData () {
      try {
        if (this.validateInternalSerials()) {
          const result = await this.$validator.validateAll()
          if (result) {
            if (this.validateQuantityNoZero()) {
              this.progress = true

              // Send Nubefact
              const response = await this.sendNubefact()
              // End

              let obj = {
                ...this.document,
                products: [...this.addedProducts],
                user: {
                  uid: auth.currentUser.uid,
                  displayName: auth.currentUser.displayName
                },
                serial: 'TTT1',
                sequence: response.sequence,
                nubefact: {
                  ...response.nubefact
                },
              }

              // Save in firestore
              const doc = await db.collection('transfers').add({
                ...obj,
                createdAt: FieldValue.serverTimestamp()
              })
              obj.id = doc.id
              obj.createdAt = new Date()
              obj.accepted = false
              // End

              // Verify guide
              setTimeout(async () => {
                await this.verifyGuide(obj)
                this.$emit('closeSidebar')
                this.$emit('add', obj)
                this.$vs.notify({
                  color: 'success',
                  title: 'Traslados',
                  text: 'Traslado registrado correctamente.'
                })
                this.progress = false
              }, 7000)
              // End
            }
            else {
              this.$vs.notify({
                color: 'danger',
                title: 'Error',
                text: 'No se pueden trasladar 0 productos'
              })
              this.progress = false
            }
          }
        }
        else {
          this.$vs.notify({
            color: 'warning',
            title: 'Traslados',
            text: 'Debes verificar las series internas de todos los productos.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    },
    /**
     * Validate that no product has stock 0
     * */
    validateQuantityNoZero () {
      let flag = 1
      this.addedProducts.forEach((p) => {
        if (p.quantity <= 0) {
          flag = 0
        }
      })
      return flag === 1 && this.addedProducts.length !== 0
    },
    /**
     * Add product
     */
    async addProduct () {
      try {
        if (this.selectedProduct.id && this.document.warehouseOut) {
          this.progress = true
          const productDoc = await db.collection('products').doc(this.selectedProduct.id).collection('stocks').doc(this.document.warehouseOut.id).get()
          if (productDoc.exists) {
            this.addedProducts.push({
              ...this.selectedProduct,
              quantity: 1,
              stock: productDoc.data().stock
            })
            const indexProduct = this.allProducts.findIndex((p) => p.id === this.selectedProduct.id)
            this.allProducts.splice(indexProduct, 1)
            this.selectedProduct = {}
          }
          else {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: 'Producto no registrado en almacén'
            })
          }
        }
        else {
          this.$vs.notify({
            color: 'danger',
            title: 'Error',
            text: 'Selecciona un producto y almacén de salida'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**00
     * Delete product
     */
    deleteProduct (product) {
      this.allProducts.push(product)
      const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
      this.addedProducts.splice(indexProduct, 1)
    },
    /**
     * */
    openDialog (p) {
      this.selectedProduct = p
      this.openSerialDialog = true
      if (p.serials) {
        this.serials = p.serials
      }
    },
    /**
     * Close Dialog
     */
    clearDialog () {
      this.serials = ''
    },
    /**
     * Accept Dialog
     */
    async acceptAlert () {
      const productIndex = this.addedProducts.findIndex((o) => {
        return this.selectedProduct.id === o.id
      })


      this.addedProducts[productIndex].serialsArray = _.cloneDeep(this.serials).split('\n')
      this.addedProducts[productIndex].serials = _.cloneDeep(this.serials)


      /**
      for await (let [index, s] of this.addedProducts[productIndex].serialsArray.entries()) {
        const serialDoc = await db.collection('serials').doc(s).get()
        if (serialDoc.exists) {
          if (serialDoc.data().productId !== this.addedProducts[productIndex].id) {
            this.addedProducts[productIndex].serialsArray.splice(index, 1)
            this.addedProducts[productIndex].serials = this.addedProducts[productIndex].serialsArray.join('\n')
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: 'La serie ' + s + ' no es válida'
            })
          }
        }
        else {
          this.addedProducts[productIndex].serialsArray.splice(index, 1)
          this.addedProducts[productIndex].serials = this.addedProducts[productIndex].serialsArray.join('\n')
          this.$vs.notify({
            color: 'danger',
            title: 'Error',
            text: 'La serie ' + s + ' no es válida'
          })
        }
      }
      // End
       **/

      this.selectedProduct = {}
      this.serials = ''
      this.openSerialDialog = false
    },
    /**
     * Close dialog
     */
    closeDialog () {
      this.selectedProduct = {}
      this.openSerialDialog = false
      this.serials = ''
    },
    /**
     * Seng guide tu nubefact
     */
    async sendNubefact () {
      const items = []
      this.addedProducts.forEach((p) => {
        const obj = {
          'unidad_de_medida': 'NIU',
          'codigo': p.sku,
          'descripcion': p.name,
          'cantidad': p.quantity
        }
        if (p.serials) {
          obj.descripcion = obj.descripcion += (' Series: ' + p.serials.split('\n').join())
        }
        items.push(obj)
      })
      const document = {
        'operacion': 'generar_guia',
        'tipo_de_comprobante': 7,
        'serie': 'TTT1',
        'cliente_tipo_de_documento': 6,
        'cliente_numero_de_documento': '20471861619',
        'cliente_denominacion': 'Importaciones Vialex SAC',
        'cliente_direccion': this.document.warehouseIn.address,
        'cliente_email': this.document.warehouseIn.warehouseManager.email,
        'cliente_email_1': '',
        'cliente_email_2': '',
        'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
        'observaciones': this.document.motive,
        'motivo_de_traslado': '04',
        'peso_bruto_total': '1',
        "peso_bruto_unidad_de_medida": "KGM",
        'numero_de_bultos': '0',
        'tipo_de_transporte': '02',
        'fecha_de_inicio_de_traslado': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),

        'transportista_documento_tipo': this.document.merchant.typeDocument.value === 'DNI' ? 1 : 7,
        'transportista_documento_numero': this.document.merchant.document,
        'transportista_denominacion': this.document.merchant.name,
        'transportista_placa_numero': 'ALJ-444', // Reemplazar

        'conductor_documento_tipo': this.document.merchant.typeDocument.value === 'DNI' ? 1 : 7,
        'conductor_documento_numero': this.document.merchant.document,
        'conductor_nombre': this.document.merchant.name,
        'conductor_apellidos': this.document.merchant.lastName,
        'conductor_numero_licencia': this.document.merchant.license,

        'punto_de_partida_ubigeo': this.document.warehouseOut.ubigeo,
        'punto_de_partida_direccion': this.document.warehouseOut.address,
        'punto_de_llegada_ubigeo': this.document.warehouseIn.ubigeo,
        'punto_de_llegada_direccion': this.document.warehouseIn.address,
        'enviar_automaticamente_a_la_sunat': false,
        'enviar_automaticamente_al_cliente': false,
        'codigo_unico': '',
        'formato_de_pdf': ''
      }
      const response = await this.$http.post('https://southamerica-east1-imvialex.cloudfunctions.net/nubefact-sendReferenceGuides', {
        document,
        items
      }, {
        headers: {
          'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
        }
      })
      return response.data
    },
    /**
     * Verify guide
     * @param tr
     */
    async verifyGuide (tr) {
      try {
        if (!tr.accepted) {
          const response = await this.$http.post('https://southamerica-east1-imvialex.cloudfunctions.net/nubefact-verifyGuide', {
            document: {
              'operacion': 'consultar_guia',
              'tipo_de_comprobante': 7,
              'serie': tr.serial,
              'numero': tr.sequence
            }
          }, {
            headers: {
              Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
            }
          })

          /// Update in firestore
          await db.collection('transfers').doc(tr.id).update({
            nubefact: {
              ...response.data.nubefact
            },
            accepted: response.data.nubefact.aceptada_por_sunat
          })
          // End

          if (response.data.nubefact.aceptada_por_sunat) {
            // Open PDF in browser
            window.open(response.data.nubefact.enlace_del_pdf, 'blank')
            // End
            // Update row
            tr.nubefact = {
              ...response.data.nubefact
            }
            tr.accepted = true
            // End

            this.$vs.notify({
              color: 'success',
              title: 'Guía de remisión',
              text: 'Guía aceptada por Sunat',
              time: 5000
            })
          }
          else {
            this.$vs.notify({
              color: 'warning',
              title: 'Guía de remisión',
              text: 'Guía no aceptada por Sunat',
              time: 5000
            })
          }
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
        })
      }
    },
    /**
     * Validate internal serials products
     */
    validateInternalSerials () {
      let flag = true
      for (let i = 0; i < this.addedProducts.length; i++) {
        let p = this.addedProducts[i]
        if (!p.serialsArray || p.serialsArray.length === 0 || p.serialsArray.length !== p.quantity) {
          flag = false
          break
        }
      }
      return flag
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
